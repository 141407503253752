








































// 搜索页面
import {
  defineComponent,
  onMounted,
  toRefs,
  inject,
} from "@vue/composition-api";
import bgImg from "@/components/bgImg/index.vue";
import offcanvas from "@/components/offcanvas/index.vue";
import Menu from "@/components/menu/index.vue";
import InfoWarp from "@/components/infowarp/index.vue";
import { useList, getListToTypeFn, useDom } from "@/use";
import { getSearch } from "@/api/index";
export default defineComponent({
  components: {
    bgImg,
    offcanvas,
    Menu,
    InfoWarp,
  },
  name: "index",
  setup() {
    const { skipPage } = useDom();
    const title = "搜索结果";
    const activeId = 0;
    const Mdata = [
      {
        children: [],
        id: 0,
        label: "搜索结果",
      },
    ];
    // inject('reload')

    const {
      state,
      getTableListData,
      query,
      meunActive,
      getListMenuFn,
      resetState,
    } = useList("", () => {
      return new Promise((reslove) => {
        // 获取表格数据、导入接口
        getSearch({
          title: query.value,
          pageNum: state.listQuery.pageNum,
          pageSize: state.listQuery.pageSize,
        })
          .then((res) => {
            reslove(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    });

    onMounted(async () => {
      const { value } = query as IProps;
      getTableListData();
      localStorage.setItem("query", value);
      // console.log(inject("reload"));
    });

    const handleRouter = (row: any) => {
      if (!row.type) return false;
      row.type = row.type == "product" ? "products" : row.type;
      skipPage(`/${row.type}-info`, {
        type: "details",
        id: row.id,
        menuactive: row.dataDictionaryId,
      });
    };
    return {
      query,
      title,
      Mdata,
      activeId,
      ...toRefs(state),
      handleRouter,
    };
  },
});
